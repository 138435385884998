import initAnchorLinks from "./modules/anchor-links";

import { Swiper, Scrollbar } from "swiper";
Swiper.use([Scrollbar]);
import "swiper/swiper-bundle.min.css";

const swiper = new Swiper(".portfolio__swiper", {
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  // loopFillGroupWithBlank: true,
  scrollbar: {
    el: ".swiper-scrollbar",
    // hide: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },

    768: {
      spaceBetween: 120,
      slidesPerView: 1.85,
      slidesPerGroup: 1,
      centeredSlides: true,
      clickable: true,
    },
  },
});

// Определяем функции для отображения сообщения об ошибке
function printError(elemId, hintMsg) {
  document.getElementById(elemId).innerHTML = hintMsg;
}

let form = document.querySelector(".form-form");
let sendButton = document.querySelector("#send-button");
console.log("addEventListener submit");
form.addEventListener("submit", function (event) {
  event.preventDefault();
  validateForm();
});
form.addEventListener("input", checkValidity);
function validateForm() {
  console.log("validateForm");
  let name = document.querySelector("#text-field__name");
  let nameValue = name.value;

  let email = document.querySelector("#text-field__email");
  let emailValue = email.value;

  let phone = document.querySelector("#text-field__phone");
  let phoneValue = phone.value;

  let textarea = document.querySelector(".text-field__comment-textarea_item");
  let textareaValue = textarea.value;

  // Определяем переменные ошибок со значением по умолчанию
  let nameErr = (profileErr = textareaErr = true);

  // Проверяем имя
  if (nameValue === "") {
    name.classList.add("error");
  } else {
    nameErr = false;
  }

  // Проверяем адрес электронной почты
  if (emailValue == "") {
    printError("emailErr", "Заполните поле");
    email.classList.add("error");
  } else {
    // Регулярное выражение для базовой проверки электронной почты
    // let regex = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/;
    let regex = /^\S+@\S+\.\S+$/;
    if (regex.test(emailValue) === false) {
      printError("emailErr", "Укажите корректный e-mail");
      email.classList.add("error");
    } else {
      printError("emailErr", "");
      emailErr = false;
    }
  }

  // Проверяем номер мобильного телефона
  if (phoneValue == "") {
    printError("phoneErr", "Заполните поле");
    phone.classList.add("error");
  } else {
    let regex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/;
    // let regex = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/;
    if (regex.test(phoneValue) === false) {
      printError("phoneErr", "Укажите корректный телефон");
      phone.classList.add("error");
    } else {
      printError("phoneErr", "");
      phoneErr = false;
    }
  }

  // Проверяем текстовое поле
  if (textareaValue == "") {
    printError("textareaErr", "Введите Ваше сообщение");
    textarea.classList.add("error");
  } else {
    printError("textareaErr", "");
    textareaErr = false;
  }

//   if (!confirmCheck.checked) {
//     confirmCheck.classList.add("error");
//     confirmCheckWrap.classList.add("error");
//   } else {
//     confirmCheck.classList.remove("error");
//     confirmCheckWrap.classList.remove("error");
//   }

  // Запрещаем отправку формы в случае ошибок
  if (
    (nameErr || emailErr || phoneErr || textareaErr) == true
  ) {
    return false;
  } else {
    sendForm();
  }
}

async function sendData() {
  let data = new FormData(form);
  let array = Array.from(data.entries());
  let raw = JSON.stringify(Object.fromEntries(array));

  let response = await fetch("https://incrussia.ru/", {
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body: raw,
  });

  let result = await response.json();
  if (result.status === "success" || result.status === "200") {
    onSuccess();
  } else {
    onError();
  }
}

async function sendForm(event) {
    console.log('sendFOrm')
//   toggleLoader();
//   sendData();
//   toggleLoader();
}

let textFields = document.querySelectorAll(".text-field__block");

textFields.forEach((textField) => {
  let requiredInput = textField.querySelector(".text-field__input");
  let errorText = textField.querySelector(".error");

  if (requiredInput) {
    requiredInput.addEventListener("input", () => {
      requiredInput.classList.remove("error");
      errorText.innerHTML = "";
    });
  }
});

let textarea = document.querySelector("#story-textarea");
textarea.addEventListener("input", autoResize, false);

function autoResize() {
  // document.querySelector('.text-field__comment-textarea').classList.add('resize');
  this.style.height = "auto";
  this.style.height = this.scrollHeight + "px";
}

function toggleLoader() {
  const loader = document.querySelector(".text-field__loader");
  loader.classList.toggle("text-field__hidden");
}

function onSuccess() {
  const inputs = document.querySelectorAll(".text-field__input");
  const button = document.querySelector(".text-field__button");
  inputs.forEach((input) => {
    input.value = "";
  });
  document.querySelector(".checkbox__input-confirm").checked = false;
  button.disabled = true;
  const success = document.querySelector(".text-field__success");
  success.classList.toggle("text-field__hidden");
  // document.querySelector('.text-field__comment-textarea').classList.remove('resize');
}

function onError() {
  const error = document.querySelector(".text-field__error");
  error.classList.toggle("text-field__hidden");
}

function checkValidity(event) {
  const formNode = event.target.form;
  const isValid = formNode.checkValidity();
  formNode.querySelector("button").disabled = !isValid;
}

function setMask() {
    [].forEach.call(document.querySelectorAll(".js-phone"), function (input) {
        let keyCode;
        function mask(event) {
            event.keyCode && (keyCode = event.keyCode);
            let pos = this.selectionStart;
            if (pos < 3) event.preventDefault();
            let matrix = "+7 (___) ___ __ __",
                i = 0,
                def = matrix.replace(/\D/g, ""),
                val = this.value.replace(/\D/g, ""),
                new_value = matrix.replace(/[_\d]/g, function (a) {
                    return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
                });
            i = new_value.indexOf("_");
            if (i !== -1) {
                i < 5 && (i = 3);
                new_value = new_value.slice(0, i);
            }
            let reg = matrix
                .substr(0, this.value.length)
                .replace(/_+/g, function (a) {
                    return "\\d{1," + a.length + "}";
                })
                .replace(/[+()]/g, "\\$&");
            reg = new RegExp("^" + reg + "$");
            if (
                !reg.test(this.value) ||
                this.value.length < 5 ||
                (keyCode > 47 && keyCode < 58)
            )
                this.value = new_value;
            if (event.type == "blur" && this.value.length < 5) this.value = "";
        }

        input.addEventListener("input", mask, false);
        input.addEventListener("focus", mask, false);
        input.addEventListener("blur", mask, false);
        input.addEventListener("keydown", mask, false);
    });
}

setMask();

// Инициализируем якорные ссылки с плавной прокруткой
initAnchorLinks(document, window);
initAnchorLinks(document, window, 'anchor-link a[data-anchor^="#"]');

const header = document.querySelector(".header");
const menuButton = document.querySelector(".header__menu-button");
const headerNav = document.querySelector(".header__nav");
const body = document.querySelector(".page");

if (menuButton) {
  menuButton.addEventListener("click", () => {
    menuButton.classList.toggle("header__menu-button_close");
    body.classList.toggle("no-scroll");
    headerNav.classList.toggle("show");
    header.classList.toggle("header__show");
  });
}

const headerLinks = document.querySelectorAll(".header__list-item");

headerLinks.forEach((link) => {
  link.addEventListener("click", function () {
    menuButton.classList.remove("header__menu-button_close");
    body.classList.remove("no-scroll");
    headerNav.classList.remove("show");
    header.classList.remove("header__show");
  });
});

const tasksItems = document.querySelectorAll(".tasks__list-item");

tasksItems.forEach((link) => {
  const text = link.querySelector(".tasks__list-item_text");
  link.addEventListener("click", function () {
    text.classList.toggle("show");
  });
});
